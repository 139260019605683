var windowWidth, windowHeight, currentScroll;
$(document).ready(function() {
    init();
    scrollToTop(); //Scrolls to top
    initSwiper(); //Swiper initialization
    flashClose(); //Flash message close action
    cookieLaw(); //Cookie law controller
    preventFOUC('.fouc'); //Prevents Flash Of Unstyled Content
    /* statisticsCounter(); */
    initMap();
    cardFlipper();
    if (isScrolledIntoView('.statistics')) {
        statisticsCounter();
    }
    //sameHeightColumns('.post-wrapper>div')
    socialMediaHover();
    stickyMenu();
    customFileUpload();
    calculateBodyContentMinHeight();
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox({
        alwaysShowClose: true,
    });
});

$(window).load(function() {
    $('body').animate({
        opacity: 1,
    }, 400);
});

function onLoadEvents() {
    checkCookie();
    initSwiper();
    /* document.getElementsByTagName('body')[0].removeAttribute('style') */
    //sameHeightColumns('.post-wrapper>div')
    $('body').animate({
        opacity: 1,
    }, 400);
}

$(document).scroll(function() {
    currentScroll = $(window).scrollTop();
    if (isScrolledIntoView('.statistics')) {
        statisticsCounter();
    }
    stickyMenu();
});

$(window).resize(function() {
    if (windowWidth != $(window).width() || windowHeight != $(window).height()) {
        init();
        //sameHeightColumns('.post-wrapper>div');
        calculateBodyContentMinHeight();
    }

});

function init() {
    currentScroll = $(window).scrollTop();
    windowWidth = $(window).width();
    windowHeight = $(window).height();

    $('a, button').click(function() {
        this.blur(); // or $(this).blur();
    });
    if (isTouchDevice()) {
        $('a, button').on('touchend', function() {
            this.blur(); // or $(this).blur();
        });
    }

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('a.btn.disabled').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
    });
    //edgeScrollFix();
    //sameHeightColumns('.post-wrapper>div');
    $('.select2').select2({
        width: 'resolve',
        minimumResultsForSearch: Infinity
    });
    var scrollOffset = ((windowWidth > 768) ? 110 : 66);
    var scroll = new SmoothScroll('[data-scroll]', {
        header: '.navbar-sticky',
        speed: 1000,
        easing: 'easeInOutQuad',
        before: function() {
            if (windowWidth < 768) {
                $('.navbar-toggle').trigger('click');
            }
        }
    });
}


function initSwiper() {
    var sliderHeader = new Swiper('.swiper-header', {
        slidesPerView: 1,
        pagination: '.swiper-header .swiper-pagination',
        prevButton: '.swiper-header .swiper-button-prev',
        nextButton: '.swiper-header .swiper-button-next',
        paginationClickable: true,
        loop: true,
        autoplay: 6500,
    });
    var sliderPartners = new Swiper('.swiper-partners', {
        slidesPerView: 4,
        prevButton: '.swiper-partners-outside .swiper-button-prev',
        nextButton: '.swiper-partners-outside .swiper-button-next',
        loop: true,
        spaceBetween: 30,
        autoplay: 2500,
        breakpoints: {
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 4,
            }
        }
    });
    var sliderTestimonials = new Swiper('.swiper-testimonials', {
        slidesPerView: 1,
        pagination: '.swiper-testimonials .swiper-pagination',
        paginationClickable: true,
        loop: true,
        autoplay: 4500,
        autoHeight: false,
    });

    var sliderCollegues =  new Swiper('.swiper-colleague', {
        slidesPerView: 4,
        /* prevButton: '.swiper-colleague-outside .swiper-button-prev',
        nextButton: '.swiper-colleague-outside .swiper-button-next', */
        loop: true,
        freeMode: true,
        autoplay: 10,
        delay: 10,
        autoplayDisableOnInteraction: false,
        speed: 9679,
        spaceBetween: 15,
        grabCursor: true,
        breakpoints: {
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },
        },
        onInit: function(swiper, event) {
            setTimeout(function() {
                var colleaguesNum = parseInt($('.swiper-colleague').data('colleagues'));

                /* if(colleaguesNum > swiper.params.slidesPerView) {
                    swiper.params.loop = true;
                    swiper.update();
                } */
                swiper.params.autoplay = 10;
                swiper.startAutoplay();
            }, 700);
        }
    });
    /* $('.swiper-colleague-outer .swiper-button-next').on('mouseenter', function() {
        $('.swiper-colleague')[0].swiper.stopAutoplay();
    })
    $('.swiper-colleague-outer .swiper-button-next').on('mouseleave', function() {
        $('.swiper-colleague')[0].swiper.startAutoplay();
    })
    $('.swiper-colleague-outer .swiper-button-next').on('click', function() {
        var slideWidth = $('.swiper-colleague-outer .swiper-slide').innerWidth();
        var currTranslate = $('.swiper-colleague')[0].swiper.getWrapperTranslate();
        var isInTransition = $('.swiper-colleague')[0].swiper.animating;
        console.log(isInTransition);
        if(isInTransition) {
            console.log('transition');
            $('.swiper-colleague')[0].swiper.setWrapperTranslate(currTranslate - slideWidth);
        }
        else {
            $('.swiper-colleague')[0].swiper.setWrapperTranslate(currTranslate - slideWidth);
            //$('.swiper-colleague')[0].swiper.slideNext();
        }
        $('.swiper-colleague')[0].swiper.update();
        $('.swiper-colleague')[0].swiper.updateProgress();
        $('.swiper-colleague')[0].swiper.updateClasses();
    })
    $('.swiper-colleague-outer .swiper-button-prev').on('mouseenter', function() {
        $('.swiper-colleague')[0].swiper.stopAutoplay();
    })
    $('.swiper-colleague-outer .swiper-button-prev').on('mouseleave', function() {
        $('.swiper-colleague')[0].swiper.startAutoplay();
    })
    $('.swiper-colleague-outer .swiper-button-prev').on('click', function() {
        var slideWidth = $('.swiper-colleague-outer .swiper-slide').innerWidth();
        var currTranslate = $('.swiper-colleague')[0].swiper.getWrapperTranslate();
        var isInTransition = $('.swiper-colleague')[0].swiper.animating;
        if(isInTransition) {
            $('.swiper-colleague')[0].swiper.setWrapperTranslate(currTranslate + slideWidth);
        }
        else {
            $('.swiper-colleague')[0].swiper.setWrapperTranslate(currTranslate + slideWidth);
            //$('.swiper-colleague')[0].swiper.slidePrev();
        }
        $('.swiper-colleague')[0].swiper.update();
        $('.swiper-colleague')[0].swiper.updateProgress();
        $('.swiper-colleague')[0].swiper.updateClasses();
    })*/
     $('.swiper-colleague').on('mouseenter', function() {
        // console.log('mouseevent enter')
        $('.swiper-colleague .swiper-wrapper').css({'transition-duration': '0ms'});
        $('.swiper-colleague')[0].swiper.stopAutoplay();
    });
    $('.swiper-colleague').on('mouseleave', function() {
        // console.log('mouseevent leave')
        $('.swiper-colleague .swiper-wrapper').css({'transition-duration': '6500ms'});
        $('.swiper-colleague')[0].swiper.startAutoplay();
    });
    if($('.slider-colleagues > div').length > 1) {

        var slider = tns({
            container: '.slider-colleagues',
            items: 1,
            slideBy: 1,
            gutter:15,
            autoplay: true,
            speed: 3500,
            autoplayTimeout: 0,
            autoplayHoverPause: true,
            loop: true,
            mouseDrag: true,
            controlsContainer: '.custom-tns-controls',
            nav: false,
            autoplayButton: false,
            autoplayButtonOutput: false,
            responsive: {
                768: {
                    items: 2,
                },
                992: {
                    items: 4,
                },
            }
        });
        slider.getInfo();
        var sliderItemsNum = slider.getInfo().slideCountNew;
        console.log(sliderItemsNum);
            $('.custom-tns-controls .swiper-button-prev').on('mouseleave', function() {
                $('.slider-colleagues').css({'transition-duration': '3.5s;'})
                slider.play();
            })
            $('.custom-tns-controls .swiper-button-next').on('mouseleave', function() {
                $('.slider-colleagues').css({'transition-duration': '3.5s;'})
                slider.play();
            })
            $('.custom-tns-controls .swiper-button-next').on('click', function() {
                slider.pause();
                slider.goTo('next');
            })
            $('.custom-tns-controls .swiper-button-prev').on('click', function() {
                slider.pause();
                slider.goTo('prev');
            })
    }
}


function preventFOUC(foucClass) {
    $(foucClass).removeClass(foucClass);
}

function scrollToTop() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('.scrollup').show();
        }
        if ($(this).scrollTop() > 300) {
            $('.scrollup').show();
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    $('.scrollup').click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 600);
        return false;
    });
}

/* function edgeScrollFix() {
    if (navigator.userAgent.match(/Trident\//) || navigator.userAgent.match(/Edge\//)) {
        document.body.addEventListener("mousewheel", function(evt) {
            evt.preventDefault();
            var wd = evt.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
} */

function cookieLaw() {
    $('.cookie-container button').on('click', function() {
        setCookie('accepted', true, 30);
        $('.cookie-container').addClass('hidden');
    });
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkCookie() {
    var accepted = getCookie('accepted');
    if (accepted == 'true') {
        return;
    } else {
        $('.cookie-container').removeClass('hidden');
    }
}

function flashClose() {
    $('.flash-message-container .close').on('click', function() {
        $(this).parents('.flash-message-container').fadeOut(400);
    });
}

function statisticsCounter() {
    $('.statistics').addClass('in-view');
    $('.statistics-item').each(function() {
        var number = $(this).find('.statistic-number').data('number');
        var statOutput = $(this).find('.number');
        var countTo = number;
        var start = 0;

        $({ countNum: start }).animate({
            countNum: countTo
        }, {
            duration: 2500,
            easing: 'linear',
            step: function() {
                statOutput.text(Math.floor(this.countNum));
            },
            complete: function() {
                statOutput.text(this.countNum + '');
            }
        });
    });
}

function isScrolledIntoView(elem) {
    if ($(elem).length == 0) {
        return;
    } else {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && (!$(elem).hasClass('in-view'))) {
            return true;
        } else if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && ($(elem).hasClass('in-view'))) {
            return false;
        }
    }
};

function initMap() {
    if ($('#map').length) {
        var tranzpress = { lat: 47.517883, lng: 19.013209 };
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 18,
            center: tranzpress,
            styles: [{
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#f5f5f5"
                    }]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "color": "#f5f5f5"
                    }]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#bdbdbd"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#eeeeee"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#e5e5e5"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#ffffff"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#dadada"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#e5e5e5"
                    }]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#eeeeee"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#c9c9c9"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                }
            ]
        });
        var contentString = '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h4 id="firstHeading" class="firstHeading text-center">TranzPress Kft., Detrekő u. 12</h4>' +
            '<div id="bodyContent">' +
            '</div>' +
            '</div>';
        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });
        var marker = new google.maps.Marker({
            position: tranzpress,
            map: map,
            title: 'TranzPress Kft., Detrekő u. 12'
        });
        infowindow.open(map, marker);
        map.panBy(-185, 0);
    }
}

function cardFlipper() {

    if (isTouchDevice()) {
        $('.card-item .turn-back').removeClass('visible-xs-block');
        $('.flip-container').on('touchstart', function() {
            $('.flip-container').removeClass('flip');
            $(this).addClass('flip');

        })
        $('.card-item .turn-back').on('touchstart', function(e) {
            $(this).parents('.flip-container').removeClass('flip');
            e.stopPropagation();
            return;
        });
    } else {
        $('.flip-container').on('mouseenter', function() {
            $(this).addClass('flip');

        })
        $('.flip-container').on('mouseleave', function(e) {
            $(this).removeClass('flip');
        });
    }
}

function isTouchDevice() {
    var isTouch = !!("ontouchstart" in window) || window.navigator.msMaxTouchPoints > 0;

    return isTouch;
}

function socialMediaHover() {
    $('.icon-fb').on('mouseenter', function() {
        $(this).parent('div').next('div').text('Facebook').css({ 'color': '#00b3af' });
    });
    $('.icon-fb').on('mouseleave', function() {
        $(this).parent('div').next('div').text('Social media').removeAttr('style');
    });
    $('.icon-linkedin').on('mouseenter', function() {
        $(this).parent('div').next('div').text('LinkedIn').css({ 'color': '#00b3af' });
    });
    $('.icon-linkedin').on('mouseleave', function() {
        $(this).parent('div').next('div').text('Social media').removeAttr('style');
    });
}

function stickyMenu() {
    if (currentScroll > ($('.navbar').innerHeight() / 2)) {
        $('.navbar').addClass('navbar-sticky');
        $('.body-content').css({ 'margin-top': $('.navbar').innerHeight() + 'px' });
    } else {
        $('.navbar').removeClass('navbar-sticky');
        $('.body-content').css({ 'margin-top': 0 });
    }
}

function sameHeightColumns(selector) {
    var maxHeight = 0;
    $(selector).each(function() {
        if ($(this).innerHeight() > maxHeight) {
            maxHeight = $(this).innerHeight();
        }
    })
    $(selector).parent().height(maxHeight);
}

function customFileUpload() {
    var inputs = document.querySelectorAll('.inputfile');
    Array.prototype.forEach.call(inputs, function(input) {
        var label = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener('change', function(e) {
            var fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();

            if (fileName)
                document.querySelector('.input-file-name').innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });

        // Firefox bug fix
        input.addEventListener('focus', function() { input.classList.add('has-focus'); });
        input.addEventListener('blur', function() { input.classList.remove('has-focus'); });
    });

    $('.upload-trigger, .input-file-name').on('click', function() {
        $('.inputfile').trigger('click');
    });
}

function calculateBodyContentMinHeight() {
    var navbarHeight = $('.navbar').innerHeight();
    var footerHeight = $('footer').innerHeight();
    var bodyContentMinHeight = $(window).innerHeight() - (navbarHeight + footerHeight)

    $('.body-content').css('min-height', bodyContentMinHeight + 'px');
}
